import 'core-js';
import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import WorkingHours from './component/WorkingHours';

const elements = document.getElementsByClassName('js-time');

if (elements.length > 0) {
    [...elements].forEach((element) => {
        if (element) {
            const workingHoursTimeStamps = JSON.parse(element.getAttribute('working-hours'));

            render(
                <WorkingHours workingHours={workingHoursTimeStamps} />,
                element,
            );
        }
    });
}

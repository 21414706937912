import moment from 'moment-timezone';

import capitalize from '../utils/capitalize';

const romanDays = [
    'VII',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
];

const convertByTimeZoneFactory = userTimeZone => date => moment.tz(date, userTimeZone);

const needsShortDaysFormat = locale => ['bg'].includes(locale.toLowerCase());

export const convertToTimeFactory = userTimeZone => (date) => {
    const convertByUserTimeZone = convertByTimeZoneFactory(userTimeZone);
    const convertedDate = convertByUserTimeZone(date);
    const locale = document.documentElement.lang;
    const localeData = moment.localeData(locale);
    const timeFormat = localeData.longDateFormat('LT');
    if (convertedDate.isValid()) {
        return {
            hours: convertedDate.format(timeFormat),
            day: needsShortDaysFormat(locale) ? localeData.weekdaysMin(convertedDate) : romanDays[convertedDate.day()],
        };
    }
    return {
        hours: null,
        day: null,
    };
};

const convertTimeStampsToHours = (workingHoursTimeStamp) => {
    const workDayStart = moment(workingHoursTimeStamp.workday_start * 1000);
    const workDayEnd = moment(workingHoursTimeStamp.workday_end * 1000);
    const weekendStart = moment(workingHoursTimeStamp.weekend_start * 1000);
    const weekendEnd = moment(workingHoursTimeStamp.weekend_end * 1000);

    return {
        workDayStart, workDayEnd, weekendStart, weekendEnd,
    };
};

const getDayPattern = (startDay, endDay) => {
    if (startDay === endDay) {
        return capitalize(startDay);
    }

    return `${capitalize(startDay)}-${capitalize(endDay)}`;
};

const formatHours = (workStart, workEnd, convertToTime) => {
    const { hours: startHours, day: startDay } = convertToTime(workStart);
    const { hours: endHours, day: endDay } = convertToTime(workEnd);
    const isValid = workStart.isValid() && workEnd.isValid() && startHours && endHours;

    if (startHours === '12:00 AM' && endHours === '12:00 AM') {
        return isValid ? `${getDayPattern(startDay, endDay)}, 0-24 h` : '';
    }

    return isValid ? `${getDayPattern(startDay, endDay)}, ${startHours} - ${endHours}` : '';
};

const formatWorkingHours = (workingHoursTimeStamp) => {
    const userTimeZone = moment.tz.guess();
    const convertToTime = convertToTimeFactory(userTimeZone);
    const {
        workDayStart, workDayEnd, weekendStart, weekendEnd,
    } = convertTimeStampsToHours(workingHoursTimeStamp);

    const formattedWorkDayHours = formatHours(workDayStart, workDayEnd, convertToTime);
    const formattedWeekendHours = formatHours(weekendStart, weekendEnd, convertToTime);

    return `${formattedWorkDayHours} ${formattedWeekendHours}`;
};

export default formatWorkingHours;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import workingHoursFormatter from '../services/formatWorkingHours';

const WorkingHours = ({ workingHours }) => {
    const formattedWorkHours = workingHoursFormatter(workingHours);

    return (
        <Fragment>
            <span className="icon-clock" />
            <span>{formattedWorkHours}</span>
        </Fragment>
    );
};


WorkingHours.defaultProps = {
    workingHours: {
        weekday_start: null,
        weekend_end: null,
    },
};

WorkingHours.propTypes = {
    workingHours: PropTypes.shape({
        workday_start: PropTypes.number.isRequired,
        workday_end: PropTypes.number.isRequired,
        weekend_start: PropTypes.number,
        weekend_end: PropTypes.number,
    }),
};

export default WorkingHours;
